<template>

<div class="loadFail table-hook fadeInRight">
    <el-table :data="tableData" stripe border>
        <el-table-column label="导入表编号" prop="importCardId" width="300px"></el-table-column>
        <el-table-column label="订单号" prop="iccidMark" width="180px"></el-table-column>
        <el-table-column label="错误描述" prop="remark"></el-table-column>
        <el-table-column label="操作" prop="opt">
            <template slot-scope="scope">
                <a href="javascript:void(0)" @click="detail(scope.row.id)">详情</a>
            </template>
        </el-table-column>
    </el-table>
    <div class="pagination">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 15, 50,100,1000]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
    </div> 

    <el-dialog title="批量退款失败详情" :visible.sync="dialogVisible">
        <div>
            <p class="dia-title">导入订单号:</p>
            <el-input v-model="importCardId"></el-input>
        </div>
        <div>
            <p class="dia-title">订单号:</p>
            <el-input v-model="iccidMark"></el-input>
        </div>
        <div>
            <p class="dia-title">错误描述:</p>
            <el-input v-model="remark"></el-input>
        </div>
        <div slot="footer" class="dialog-footer">
            <el-button type="primary" class="themed-button" @click="dialogVisible = false">关闭</el-button>
        </div>
    </el-dialog>
</div>

</template>

<script type="text/ecmascript-6">

import {refundFailList,findById} from '@/api/cardManagement/refund.js'
import {messageBox,confirmBox} from '@/utils/common.js'

export default {
    data(){
        return {
            tableData:[],
            pageSize:15,
            currentPage:1,
            total:0,
            dialogVisible:false,
            importCardId:'',
            iccidMark:'',
            remark:''
        }
    },
    mounted(){
        this._refundFailList()
    },
    methods:{
        detail(id){
            findById({id}).then((res)=>{
                this.dialogVisible = true
                let data = res.entity
                this.iccidMark = data.iccidMark
                this.importCardId = data.importCardId
                this.remark = data.remark
            })
        },
        handleSizeChange(val){
            this.pageSize = val
            this._refundFailList()  
        },
        handleCurrentChange(val){
            this.currentPage = val
            this._refundFailList()
        },
        _refundFailList(){
            let params = this.$route.query
            params.pageSize = this.pageSize
            params.pageNo = this.currentPage
            refundFailList(params).then((res) =>{
                this.tableData = res.rows
                this.pageSize = res.pageSize
                this.currentPage = res.pageNo
                this.total = res.total
            })
        }
    }
}
</script>
<style>
     .loadFail .dia-title{margin-top:10px;margin-bottom:6px;}
     .margin-right15{margin-right:15px;}
</style>
